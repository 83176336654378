@font-face {
  font-family: 'SF Compact Display';
  src: url('/assets/fonts/sf-compact-display/sf-compact-display.eot'); /* IE9*/
  src: url('/assets/fonts/sf-compact-display/sf-compact-display.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('/assets/fonts/sf-compact-display/sf-compact-display.woff2') format('woff2'), /* chrome、firefox */ url('/assets/fonts/sf-compact-display/sf-compact-display.woff') format('woff'), /* chrome、firefox */ url('/assets/fonts/sf-compact-display/sf-compact-display.ttf') format('truetype'); /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
}

html, body, th, td {
  font-family: 'SF Compact Display', 'Helvetica', 'Arial', sans-serif;
}

// убираем цветовое выделение кликабельных элементов
* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  background: #fff;
  font-size: 13px;
}

h1, h2 {
  font-family: 'SF Compact Display', 'Helvetica', 'Arial', sans-serif;
  font-weight: bold;
  margin: 0;
}

h1 {
  font-size: 16px;
}

h2 {
  font-size: 14px;
}

#container {
  padding: 0;
  height: 100%;
}

#admin-main-menu {
  padding: 0;
  overflow: auto;

  @media only screen and (min-width: 767px) {
    padding: 0 1rem;
  }

  // пункт меню с дропдауном
  div.ui.item.dropdown {
    padding-left: 8px;
    padding-right: 8px;

    i.icon.dropdown {
      margin: 0;
    }
  }
}

#user-main-menu {
  padding: 0 1rem;
}

// hack - отступы для модалки побольше
@media only screen and (max-width: 767px) {
  .ui.mini.modal.login-modal {
    width: 85%;
    box-shadow: none !important;

    .header {
      border: 0;
    }

    .content {
      width: 90%;
      margin: 2rem auto;
    }

    .actions {
      border: 0;
      background: none;
    }
  }
}

// настройки редактора Quill: возможность растягивать по высоте
.editform {
  .quill {
    height: auto !important;
  }

  .ql-container {
    resize: vertical;
    overflow-y: auto;
    padding-bottom: 10px;
    min-height: 200px;
    max-height: 800px;
  }
}

// минимальная ширина прогресса
.ui.progress .bar {
  min-width: 8px;
}

// на мобилах запрещаем выделять текст на кнопках
button, .noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}
